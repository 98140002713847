import { FC, useEffect, useState } from 'react';
import { redirect, useParams } from 'react-router-dom';
import { commsTools } from 'config/listCommsTools';
import { yourLogo } from 'assets/images';
import { IDataWhitelabel } from 'store/slices/getWhitelabel/types';
import { getWhitelabelPath } from 'services/getWhitelabelPath';
import { selectLogoCommsToolForBack } from 'services/selectCommsTool';
import {
  Container,
  ContainerCommsTools,
  ContainerTexts,
  Title,
  LabelCommsTools,
  BoxCommsTools,
  ContainerCommsToolButton,
  BackgroundImageWhitelabel,
} from './style';

const ADAM_II_URL = process.env.REACT_APP_ADAM_II_URL;

export const BoxCommsToolsTest: FC<{}> = () => {
  const [dataPage, setDataPage] = useState<IDataWhitelabel | null>(null);

  const { userName } = useParams();

  const pathname = location.pathname;

  const getCurrentTimestamp = () => {
    return new Date().getTime();
  };

  console.log(pathname);

  const selectCommsTools = async (commsTool: any) => {
    if (commsTool.title === 'MS Teams') {
      redirect(
        `activate?url=${location.href}${!userName ? '&manager=true' : '&manager=false'}`
      );
      return;
    }
    const path = pathname.lastIndexOf('/');
    const newUrl = pathname.slice(0, path);
    console.log(newUrl);
    const state = window.btoa(
      JSON.stringify({
        path: newUrl.replace('/', ''),
        provider: selectLogoCommsToolForBack(commsTool?.title),
        userReference: `self-test-${userName}-${getCurrentTimestamp()}@fdte.io`,
      })
    );

    console.log(`self-test-${userName}-${getCurrentTimestamp()}@fdte.io`);

    if (state) {
      location.href = `${ADAM_II_URL}/chatIntegration?state=${state}`;
    }
  };

  useEffect(() => {
    const getWhitelabel = async () => {
      const responseData = await getWhitelabelPath(userName);
      if (responseData.status === 204) {
        redirect('invalid-invite');
        return;
      }

      setDataPage(responseData.data);
    };
    getWhitelabel();
  }, [userName, redirect]);

  return (
    <Container>
      <ContainerTexts>
        <BackgroundImageWhitelabel>
          <img
            id="image_whitelabel"
            src={yourLogo}
            alt={`logo ${dataPage?.userName} `}
            style={{
              background: '#FFF',
              maxWidth: '120px',
            }}
          />
        </BackgroundImageWhitelabel>

        <Title>Join a Test Chat with {userName} using a different chat tool</Title>
      </ContainerTexts>
      <BoxCommsTools>
        <LabelCommsTools>Join with your favorite chat tool</LabelCommsTools>
        <ContainerCommsTools>
          {commsTools
            .filter((commsTool) => !dataPage?.userProvider?.includes(commsTool.name))
            .map((commsTool) => (
              <ContainerCommsToolButton
                key={commsTool.title}
                onClick={() => selectCommsTools(commsTool)}>
                <img src={commsTool.logo} alt={commsTool.title} />
              </ContainerCommsToolButton>
            ))}
        </ContainerCommsTools>
      </BoxCommsTools>
    </Container>
  );
};
