import {
  logoDiscord,
  logoDiscord2,
  logoMsTeams,
  logoMSTeams2,
  logoSlack,
  logoSms,
  logoSMS2,
  logoTelegram,
  logoTelegram2,
  logoWhatsapp,
  logoWhatsapp2,
  slackLogo2,
} from 'assets/images';

export const commsTools = [
  {
    logo: logoMsTeams,
    logoSuccess: logoMSTeams2,
    title: 'MS Teams',
    name: 'msteams',
  },
  {
    logo: logoTelegram,
    logoSuccess: logoTelegram2,
    title: 'Telegram',
    disabled: false,
    name: 'telegram',
  },
  {
    logo: logoSlack,
    logoSuccess: slackLogo2,
    title: 'Slack',
    name: 'slack',
  },
  {
    logo: logoWhatsapp,
    logoSuccess: logoWhatsapp2,
    title: 'WhatsApp',
    name: 'whatsapp',
  },
  {
    logo: logoDiscord,
    logoSuccess: logoDiscord2,
    title: 'Discord',
    name: 'discord',
  },
  {
    logo: logoSms,
    logoSuccess: logoSMS2,
    title: 'SMS',
    name: 'sms',
  },
];
