import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(148, 189, 239, 0.5);
  border-radius: 0.625rem;
  max-width: 28.125rem;
  width: 100%;
  padding: 2em 0;
  gap: 1.563rem;
  z-index: 20;

  @media (max-width: 650px) {
    width: 90%;
  }
`;

export const LoadingSpinner = styled.span`
  width: 50px;
  height: 50px;
  border: 5px solid #2a7de1;
  border-bottom-color: #fff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ButtonAccess = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.938rem;
  line-height: 1.125rem;
  display: flex;
  align-items: center;
  text-align: center;
  background: #ffffff;
  color: #1a1e2b;
  box-shadow: 0px 0.625rem 1.563rem rgba(148, 189, 239, 0.5);
  border-radius: 5px;
  width: 12.125rem;
  height: 3rem;
  cursor: pointer;
  transition: 0.3s;
  z-index: 20;
  &:hover {
    transform: scale(1.05);
  }
`;

export const Info = styled.p`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 0.938rem;
  text-align: center;
  letter-spacing: -0.02em;
  color: #b0b0b0;
`;

export const ContainerLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 120px;
    margin-right: 80px;
  }
  @media (max-width: 360px) {
    & > img {
      width: 90px;
    }
  }
`;

export const CommsTool = styled.div`
  margin: 0;
`;

export const ContainerSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-left: 75px;
  margin-top: -10px;
`;

export const Title = styled.h3`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 1.188rem;
  line-height: 1.563rem;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1a1e2b;
  max-width: 21.875rem;

  @media (max-width: 25.625rem) {
    width: 85%;
  }
`;

export const BoxSteps = styled.div`
  background-color: #ffffff;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 21.875rem;
  gap: 1.25rem;
  padding: 1.563rem 1.25rem;

  p{
    width: 18.375rem;
    font-size: 1rem;
    text-align: center;
  }

  span {
    color: #2a7de1;
    font-size: 0.625rem;
    width: 13.188rem;
    text-align: center;
  }
  @media (max-width: 25.625rem) {
    width: 95%;
  }
`;

export const StepContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.313rem;
  p {
    font-size: 0.875rem;
  }
`;

export const Alink = styled.div`
  border: 1px solid #2a7de1;
  border-radius: 0.313rem;
  height: 2.75rem;
  width: 19.375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 1.25rem;

  p {
    color: #2a7de1;
    font-size: 0.875rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  @media (max-width: 25.625rem) {
    width: 95%;
  }
`;

export const Button = styled.a`
  width: 19.375rem;
  height: 2.75rem;
  background-color: #2a7de1;
  border-radius: 0.313rem;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.313rem;

  font-size: 0.875rem;
  font-weight: 600;
  color: #ffffff;
  text-decoration: none;

  cursor: pointer;

  @media (max-width: 25.625rem) {
    width: 95%;
  }
`;
